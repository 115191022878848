.blog-card {
  text-decoration: none;
  color: var(--white);
  display: inline-block;
  background-color: var(--dark);
  border-radius: 16px 0 16px 0;
  overflow: hidden;
  transition: 200ms ease-in-out all;
  border: 1px solid var(--white);
  box-shadow: 5px 5px var(--white);
  &:hover, &:focus-within {
    border: 1px solid var(--primary-hover);
    background-color: rgba(30, 30, 30, 0.45);
    border-radius: 0 16px 0 16px;
    box-shadow: none;
    transform: translateY(4px) !important;
    transition: all ease-in 0.2s;
    opacity: 1 !important;
  }
  img {
    object-fit: cover;
    height: 340px;
    max-height: 340px;
  }
  p {
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
    letter-spacing: normal !important;
  }
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .font-bold {
    font-size: 1.25rem !important;
    margin-top: 0.5rem !important;
  }
}

.blog-swiper {
  .swiper {
    padding: 0px 0 50px;
    width: 100%;
    height: 100%;
    .blog-end-wrapper {
      display: flex;
      height: 100%;
      min-height: 310px;
      color: var(--white);
      align-items: center;
      justify-content: center;
      .blog-end {
        text-align: center;
        font-weight: bold;
        text-decoration: none;
        font-size: 1.5rem;   
      }
    }
  }  
}