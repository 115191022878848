.blog-body {
background: rgba(255, 255, 255, 0.16);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.featured-image {
  width: 100%;
  max-width: 320px;
  aspect-ratio: 1;
  margin: 0 auto;
  object-fit: cover;
}