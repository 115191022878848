.feature-album-slider-wrapper {
  position: relative;
  z-index: 10;
  .swiper {
    width: 100% !important;
    padding-top: 0px !important;
    padding-bottom: 100px !important;
  }
  
  .swiper-slide {
    width: 250px !important;
    height: 250px !important;
    @media only screen and (min-width: 960px) {
      width: 350px !important;
      height: 350px !important;
    }
  }
  
  .swiper-slide {
    a {
      display: block !important;
      width: 100% !important;
      height: 100% !important;
      // max-width: 250px;
      // @media only screen and (min-width: 960px) {
      //   max-width: 300px;
      // }
    }
    img {
      display: block !important;
      width: 100% !important;
      height: 100%;
      object-fit: cover;
    }
  }
}

.album-map-grid-item img {
  transform: scale(0.985);
  transition: all 100ms ease-in-out;
  &:hover, &:focus {
    transform: scale(1);
    border-radius: 6px;
  }
}