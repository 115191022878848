.blog-body {
background: rgba(255, 255, 255, 0.16);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.3);
}

.featured-image {
  max-height: 400px;
  width: 100%;
  object-fit: cover;
  display: block;
}

.blog-card {
  overflow: hidden;
  &::after {
    background: var(--dark);
    position: absolute;
    content: "";
    inset: 0 0 0 0 ;
    height: 100%;
    width: 100%;
    z-index: -2;
  }
}