.floating-whatsapp-button {
  height: 40px !important;
  width: 40px !important;
  @media only screen and (min-width: 960px) {
    height: 50px !important;
    width: 50px !important;
  }
  .pi-whatsapp {
    font-size: 1.25rem;
    @media only screen and (min-width: 960px)  {
      font-size: 1.5rem;
    }
  }
}