.hero-container {
  position: relative;
  min-height: 380px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: all 200ms ease;
  &:after, &:before {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    display: block;
  }
  &:after {
    z-index: 2;
    border: 1px solid var(--primary);
    top: -20px;
    left: -20px;
  }
  &:before {
    z-index: 1;
    border: 1px solid var(--primary-hover);
    right: -20px;
    bottom: -20px;
  }
  h1 {
    text-transform: uppercase;
    text-align: center;
  }
}

.member-card {
  border: 1px solid var(--primary-hover);
  overflow: hidden;
  height: 100%;
  img {
    height: 600px;
    width: 100%;
    object-fit: cover;
    display: block;
  }
  .swiper-image-gallery-item {
    max-height: 400px;
    @media only screen and (min-width: 960px) {
      max-height: 600px;
    }
  }
  border-radius: 8px;
  .member-card-social-icons {
    a {
      text-decoration: none !important;
      cursor: pointer;
      font-size: 1rem;
      margin-right:0.5rem;
      border-bottom: none;
      color: var(--white) !important;
      transition: all 200ms ease;
      &:hover, &:focus-within {
        border-bottom: none;
        color: var(--primary-hover) !important;
      }
    }
  }
}

.section-component-alternate-wrapper {
  display: flex;
  flex-direction: column;
  .section-component-alternate {
    justify-content: space-between;
    flex-direction: row;
    display: flex;
  }
}