.ReactPiano__Keyboard {
  margin: 4rem auto;
  .ReactPiano__Key--natural{
    background: var(--white);  /* Change the default active key color to bright red */
  }
  .ReactPiano__Key--active {
    border-color: var(--primary-hover);
    background: var(--primary);  /* Change the default active key color to bright red */
  }
  
  .ReactPiano__Key--accidental {
    background: var(--dark); /* Change accidental keys to be completely black */
  }
}