.nav-sidebar-content nav, nav.nav-desktop {
    li {
      margin: 0 0rem 0 1rem;
    }
    li a {
      color: var(--white) !important;
      text-decoration: none;
      transition: all 200ms ease;
      position: relative;
      &:after{
        content: '';
        position: absolute;
        width: 0; height: 3px;
        display: block;
        margin-top: 5px;
        right: 0;
        background: var(--primary-hover);
        transition: all .2s ease;
        -webkit-transition: all .2s ease;
      }
       
      &:hover:after{
        width: 100%;
        left: 0;
        background: var(--primary);
      }
    }
  
  img.primary-logo {
    max-width: 150px !important;
    max-height: 90px;
    transition: all 200ms ease;
    object-fit: contain;
    &.scrolled {
      max-width: 100px !important;
    }
  }
  a .nav-text-title {
    transform: scale(1);
    transition: all 200ms ease;
    &.scrolled {
      transform: scale(0.75);
    }
  }

  .mobile-nav-items, .desktop-nav-items {
    li:last-child a {
      box-shadow: 5px 5px var(--white);
      border: 1px solid var(--white) !important;
      transition: all ease-in 0.2s !important;
      margin: 0 !important;
      padding: 0.457rem 0.857rem;
      background: none;
      &:after {
        background: var(--primary);
      }
      &:hover, &:focus {
        box-shadow: none;
        transform: translateY(4px) !important;
        transition: all ease-in 0.2s;
        opacity: 1 !important;
      }
    }
  }

  &.sticky {
    box-shadow: 0 0 5px rgba(0,0,0,0.25) !important;
    &.center-nav {
      box-shadow: 0 0 0px rgba(0,0,0,0) !important;
    }
  }
  &.nav-desktop {
    transition: all 200ms ease;
  }
}

.mobile-nav-wrapper {
  border-top: 1px solid var(--primary-hover);
}

.mobile-icon-nav-button {  
    color: var(--white) !important;
    border-color: var(--white) !important;
    transition: color 200ms ease;
    &:hover, &:focus {
      color: var(--primary) !important;
      border-color: var(--primary) !important;
    } 
}

.nav-social-icons {
  a {
    text-decoration: none !important;
    cursor: pointer;
    font-size: 1.6rem;
    margin-right:0.5rem;
    border-bottom: none;
    color: var(--white);
    transition: all 200ms ease;
    &:hover, &:focus-within {
      border-bottom: none;
      color: var(--primary-hover) !important;
    }
  }
}

.header-none {
  .p-sidebar-header {
    display: none;
  }
}

.announcement-bar {
  background-color: #00000074;
}


.sub-page {
  .spacer {
    height: 3rem;
  }
}

.home {
  .spacer {
    height: 0rem;
  }
}
