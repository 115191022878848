footer {
  .footer-social-icons {
    a {
      text-decoration: none !important;
      cursor: pointer;
      font-size: 1.5rem;
      margin-right:0.5rem;
      border-bottom: none;
      color: var(--white);
      &:hover, &:focus-within {
        border-bottom: none;
        color: var(--primary-hover) !important;
      }
    }
  }
  .footer-logo {
    width: 100%;
    max-width: 250px;
    max-height: 200px;
  }
}