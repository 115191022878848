.talent-primary-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: var(--primary-hover);
  border-radius: 20px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  font-size: 8px;
  padding: 0.075rem 0.275rem;
  @media only screen and (min-width: 960px) {
    font-size: 10px;
    padding: 0.275rem 0.475rem;
  }
}

.member-card {
  overflow: hidden;
}