.outline-text-wrapper {
  position: relative;
  &:after, &:before {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    display: block;
  }
  &:after {
    z-index: 2;
    border: 1px solid var(--primary);
    top: -20px;
    left: -20px;
  }
  &:before {
    z-index: 1;
    border: 1px solid var(--primary-hover);
    right: -20px;
    bottom: -20px;
  }
  .outline-text {
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: var(--white) !important;
    color: var(--dark) !important;
    text-transform: uppercase;
  }
}

.album-item {
  transition: all 200ms ease-in-out;
  z-index: 2;
  position: relative;
  overflow: hidden;
  &:after {
    transition: all 200ms ease-in-out;
    content: '';
    inset: 0 0 0 0;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, var(--dark) 50%, var(--dark) 100%);
    opacity: 0;
    transform: translateY(100%);
  }
  p {
    transition: color 200ms ease-in-out;
  }
  &:hover, &:focus {
    &:after {
      opacity: 0.75;
      transform: translateY(0px);
      background: linear-gradient(0deg, var(--dark) 50%, var(--primary) 100%);
    }
    p {
      color: var(--primary);
    }
  }
}